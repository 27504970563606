<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <QrCodeSessionEnquiryForm
      :qrCodeSession="qrCodeSessions.qrCodeSession"
      v-on:enquiryQrCode="enquiryQrCode()"
      :submitName="$t('QR.enquiry')"
    />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />
    <QrCodeSessionEnquiryTable
      v-if="qrCodeSessions.qrCodeSessionsData"
      :qrCodeSessionsData="qrCodeSessions.qrCodeSessionsData"
      v-on:setQrCodeSessionData="qrCodeSessions.qrCodeSession.fillData($event)"
    />
    <QrCodeSessionEnquirySellCard
      :qrCodeSession="qrCodeSessions.qrCodeSession"
      :qrCodeSessionsPurchase="qrCodeSessionsPurchases.qrCodeSessionsPurchase"
      v-on:refresh="refresh()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import QrCodeSessionEnquiryForm from "@/components/qrCodeSessions/QrCodeSessionEnquiryForm.vue";
import QrCodeSessionEnquiryTable from "@/components/qrCodeSessions/QrCodeSessionEnquiryTable.vue";
import QrCodeSessionEnquirySellCard from "@/components/qrCodeSessions/QrCodeSessionEnquirySellCard.vue";
import { isDataExist } from "@/utils/functions";
import QrCodeSessions from "@/models/qrCodeSessions/QrCodeSessions";
import QrCodeSessionsPurchases from "@/models/qrCodeSessionsPurchases/QrCodeSessionsPurchases";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    QrCodeSessionEnquiryForm,
    QrCodeSessionEnquiryTable,
    QrCodeSessionEnquirySellCard,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "userToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: "illustrator-something-wrong.svg",
      language: localStorage.getItem("userLanguage") || "ar",
      qrCodeSessions: new QrCodeSessions(),
      qrCodeSessionsPurchases: new QrCodeSessionsPurchases(),
    };
  },
  methods: {
    isDataExist,
    async enquiryQrCode() {
      this.isLoading = true;
      try {
        const response = await this.qrCodeSessions.qrCodeSession.enquiryQrCode(
          this.language,
          this.userAuthorizeToken,
          this.userToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.qrCodeSessions.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.qrCodeSessions.qrCodeSessionsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.qrCodeSessions.qrCodeSessionsData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.qrCodeSessions.qrCodeSessionsData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.qrCodeSessions.qrCodeSessionsData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
    refresh() {
      location.reload();
      // this.qrCodeSessions.qrCodeSessionsData = null;
      // this.qrCodeSessions.qrCodeSession.setInitialValue();
    },
  },
  async created() {},
};
</script>
