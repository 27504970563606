import axios from "axios";
import { PAGE_SIZE, STATUS, BASE_URL } from "@/utils/constants";
import BaseEntityModel from "./../general/BaseEntityModel";
import QrCodeSessionsPurchasesFilter from "./QrCodeSessionsPurchasesFilter";
import i18n from "@/i18n";
import store from "@/store";
import qrCodeSessionsPurchasesImg from "@/assets/images/cart.svg";

export default class QrCodeSessionsPurchase extends BaseEntityModel {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.qrCodeSessionsPurchasesToken = "";
    this.purchaseDetailsCurrent = "";
    this.purchaseDetailsAr = "";
    this.purchaseDetailsEn = "";
    this.purchaseDetailsUnd = "";
    this.purchaseImagePath = "";
    this.purchaseNotes = "";
    this.cardToken = "";
    this.cardData = "";
    this.userToken = "";
    this.purchaseArchiveStatus = "";
    this.startQrCodeSessionDateTime = "";
    this.endQrCodeSessionDateTime = "";
    this.durationQrCodeSession = "";
    this.mediaFile = "";
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModelBaseEntityModel(data);
      this.qrCodeSessionsPurchasesToken = data.qrCodeSessionsPurchasesToken;
      this.purchaseDetailsCurrent = data.purchaseDetailsCurrent;
      this.purchaseDetailsAr = data.purchaseDetailsAr;
      this.purchaseDetailsEn = data.purchaseDetailsEn;
      this.purchaseDetailsUnd = data.purchaseDetailsUnd;
      this.purchaseImagePath = data.purchaseImagePath;
      this.purchaseNotes = data.purchaseNotes;
      this.cardToken = data.cardToken;
      this.cardData = data.cardData;
      this.userToken = data.userToken;
      this.purchaseArchiveStatus = data.purchaseArchiveStatus;
      this.startQrCodeSessionDateTime = data.startQrCodeSessionDateTime;
      this.endQrCodeSessionDateTime = data.endQrCodeSessionDateTime;
      this.durationQrCodeSession = data.durationQrCodeSession;
    }
  }

  async getAllQrCodeSessionsPurchases(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    return await axios.get(
      `/api/QrCodeSessionsPurchases/GetAllQrCodeSessionsPurchases?language=${language}&userAuthorizeToken=${userAuthorizeToken}&timeZoneToken=&page=${pagination.selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&establishmentToken=${filterData.establishmentToken}&creationDateTime=${filterData.creationDateTime}&lastUpdateDateTime=${filterData.lastUpdateDateTime}&createdByUserToken=${filterData.createdByUserToken}&lastUpdatedByUserToken=${filterData.lastUpdatedByUserToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getDialogOfQrCodeSessionsPurchases(
    language,
    userAuthorizeToken,
    filterData = new QrCodeSessionsPurchasesFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/QrCodeSessionsPurchases/GetQrCodeSessionsPurchasesDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : qrCodeSessionsPurchasesImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: qrCodeSessionsPurchasesImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: qrCodeSessionsPurchasesImg,
      });
    }
    return options;
  }

  async getQrCodeSessionsPurchaseDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/QrCodeSessionsPurchases/GetQrCodeSessionsPurchasesDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.qrCodeSessionsPurchasesToken}`
    );
  }

  async addOrUpdateQrCodeSessionsPurchase(
    language,
    userAuthorizeToken,
    userToken,
    qrCodeSessionToken
  ) {
    let qrCodeSessionTokenPattern = userToken + "**" + qrCodeSessionToken;
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append(
      "qrCodeSessionsPurchasesToken",
      this.qrCodeSessionsPurchasesToken
    );
    formData.append("qrCodeSessionToken", qrCodeSessionTokenPattern);
    formData.append("fullCode", this.fullCode);
    formData.append("purchaseDetailsAr", this.purchaseDetailsAr);
    formData.append("purchaseDetailsEn", this.purchaseDetailsEn);
    formData.append("purchaseNotes", this.purchaseNotes);

    if (
      this.qrCodeSessionsPurchasesToken == "" ||
      this.qrCodeSessionsPurchasesToken == undefined
    ) {
      return await axios.post(
        `/api/QrCodeSessionsPurchases/AddQrCodeSessionsPurchases`,
        formData
      );
    } else {
      return await axios.post(
        `/api/QrCodeSessionsPurchases/UpdateQrCodeSessionsPurchases`,
        formData
      );
    }
  }

  async archiveQrCodeSessionsPurchase(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.qrCodeSessionsPurchasesToken,
      archiveStatus: true,
    };

    return await axios.post(
      `/api/QrCodeSessionsPurchases/ArchiveQrCodeSessionsPurchases`,
      data
    );
  }
}
