var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.qrCodeSessionsData)?_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("users.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("users.nationalID")))]),_c('th',[_vm._v(_vm._s(_vm.$t("users.phone")))]),(
              _vm.checkPrivilege(
                _vm.usersPrivilegeData.usersPrivilegeQrCodeSessionsPurchases.add
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("SellCards.sell"))+" ")]):_vm._e()])]),_c('tbody',[_c('tr',[_c('td',[_c('img',{staticClass:"item-img-table",attrs:{"src":("" + _vm.baseUrl + (_vm.qrCodeSessionsData.cardData.userOwnerData.userImagePath)),"onerror":("this.src='" + _vm.defaultImgUser + "'")}}),_vm._v(" "+_vm._s(_vm.isDataExist( _vm.qrCodeSessionsData.cardData.userOwnerData.userNameCurrent ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( _vm.qrCodeSessionsData.cardData.userOwnerData.userNationalID ))+" ")]),_c('td',{staticClass:"table-phone-number"},[_vm._v(" "+_vm._s(_vm.isDataExist( _vm.qrCodeSessionsData.cardData.userOwnerData.userPhoneWithCC ))+" ")]),(
              _vm.checkPrivilege(
                _vm.usersPrivilegeData.usersPrivilegeQrCodeSessionsPurchases.add
              )
            )?_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.QrCodeSessionEnquirySellCard",modifiers:{"QrCodeSessionEnquirySellCard":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('SellCards.sell')},on:{"click":_vm.setQrCodeSessionData}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/sellCards.svg")}})])]):_vm._e()])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }