<template>
  <div
    class="form-label-group"
    :class="className"
    :showPasswordIcon="showPasswordIcon"
  >
    <input
      :name="id"
      :id="id"
      v-bind:type="[showPassword ? 'text' : 'password']"
      v-model="theValue"
      class="form-control"
      placeholder=" "
      :maxlength="maxlength"
    />
    <label :for="id">{{ title ? title : $t("notFound") }}</label>
    <img :src="require('@/assets/images/' + imgName)" />
    <span v-if="showPasswordIcon" class="toggle-password">
      <img
        :src="
          require('@/assets/images/eye-' +
            [showPassword ? 'show' : 'hide'] +
            '.svg')
        "
        @click="showPassword = !showPassword"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: "CustomInputPassword",
  data() {
    return {
      theValue: this.value,
      showPassword: false,
    };
  },
  methods: {},
  props: {
    className: {
      type: String,
      default: "",
    },
    showPasswordIcon: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "textarea",
    },
    value: {
      default: "",
    },
    title: {
      type: String,
      default: "title",
    },
    imgName: {
      type: String,
      default: "man.svg",
    },
    maxlength: {
      type: Number,
      default: 1000000,
    },
  },
  watch: {
    theValue: function (val) {
      this.$emit("changeValue", val);
    },
  },
  async created() {},
};
</script>

<style lang="scss" scoped></style>
