<template>
  <b-modal
    id="QrCodeSessionEnquirySellCard"
    scrollable
    centered
    size="lg"
    hide-footer
  >
    <PreLoader v-if="isLoading" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/cart.svg" class="icon-lg" />
        {{ $t("QrCodeSessionsPurchases.data") }}
      </h3>
    </template>

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <TextArea
            :className="'col-md-12'"
            :id="'purchaseDetailsAr'"
            :value="qrCodeSessionsPurchase.purchaseDetailsAr"
            v-on:changeValue="qrCodeSessionsPurchase.purchaseDetailsAr = $event"
            :title="$t('QrCodeSessionsPurchases.detailsAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-12'"
            :id="'purchaseDetailsEn'"
            :value="qrCodeSessionsPurchase.purchaseDetailsEn"
            v-on:changeValue="qrCodeSessionsPurchase.purchaseDetailsEn = $event"
            :title="$t('QrCodeSessionsPurchases.detailsEn')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'purchaseNotes'"
            :value="qrCodeSessionsPurchase.purchaseNotes"
            v-on:changeValue="qrCodeSessionsPurchase.purchaseNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateQrCodeSessionsPurchase()"
        >
          {{ $t("QrCodeSessionsPurchases.sell") }}
        </button>
        <button
          name="submit"
          type="submit"
          class="btn btn-cancel"
          @click.prevent="$bvModal.hide('QrCodeSessionEnquirySellCard')"
        >
          {{ $t("cancel") }}
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import TextArea from "@/components/general/TextArea.vue";
import { STATUS } from "@/utils/constants";
import { setDataMultiLang } from "@/utils/functions";
import createToastMixin from "@/utils/create-toast-mixin";
import QrCodeSessionsPurchasesImg from "@/assets/images/cart.svg";

export default {
  name: "QrCodeSessionEnquirySellCard",
  props: ["qrCodeSession", "qrCodeSessionsPurchase"],
  mixins: [createToastMixin],
  components: {
    PreLoader,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: QrCodeSessionsPurchasesImg,
    };
  },
  methods: {
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.qrCodeSessionsPurchase.mediaFile = file[0];
      }
    },
    async addOrUpdateQrCodeSessionsPurchase() {
      this.isLoading = true;
      try {
        const response =
          await this.qrCodeSessionsPurchase.addOrUpdateQrCodeSessionsPurchase(
            this.language,
            this.userAuthorizeToken,
            this.userToken,
            this.qrCodeSession.qrCodeSessionToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.$bvModal.hide("QrCodeSessionEnquirySellCard");
          this.qrCodeSessionsPurchase.setInitialValue();
          this.$emit("refresh");
          this.showMsg(response.data.msg, true);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "userToken"]),
  },
  async created() {},
};
</script>
