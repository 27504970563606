<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <CustomInputPassword
            :className="'col-md-9'"
            :showPasswordIcon="false"
            :id="'qrCodeSessionToken'"
            :value="qrCodeSession.qrCodeSessionToken"
            :title="$t('QR.code')"
            :imgName="'number.svg'"
            v-on:changeValue="qrCodeSession.qrCodeSessionToken = $event"
          />
          <button
            name="submit"
            type="submit"
            class="btn btn-submit btn-enquiry col-md-3"
            @click.prevent="enquiryQrCode"
          >
            {{ submitName }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInputPassword from "@/components/general/CustomInputPassword.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "QrCodeSessionEnquiryForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInputPassword,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["qrCodeSession", "submitName"],
  methods: {
    async enquiryQrCode() {
      // decoding token

      // this.qrCodeSession.qrCodeSessionToken = this.deCodeBase64(
      //   this.qrCodeSession.qrCodeSessionToken
      // );

      this.$emit("enquiryQrCode");
    },
    enCodeBase64(str) {
      // encoding token
      return str ? window.btoa(str) : str;
    },
    deCodeBase64(encoded) {
      // decoding token
      return encoded ? window.atob(encoded) : encoded;
    },
  },
  watch: {},
  async created() {
    // var encoded = window.btoa("abc");
    // console.log("ecoded value:", encoded);
    // var decoded = window.atob(encoded);
    // console.log("decoded value:", decoded);
  },
};
</script>

<style lang="scss"></style>
