<template>
  <div v-if="qrCodeSessionsData" class="my-card">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>{{ $t("users.name") }}</th>
            <th>{{ $t("users.nationalID") }}</th>
            <th>{{ $t("users.phone") }}</th>
            <th
              v-if="
                checkPrivilege(
                  usersPrivilegeData.usersPrivilegeQrCodeSessionsPurchases.add
                )
              "
            >
              {{ $t("SellCards.sell") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img
                class="item-img-table"
                :src="`${baseUrl}${qrCodeSessionsData.cardData.userOwnerData.userImagePath}`"
                :onerror="`this.src='${defaultImgUser}'`"
              />
              {{
                isDataExist(
                  qrCodeSessionsData.cardData.userOwnerData.userNameCurrent
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  qrCodeSessionsData.cardData.userOwnerData.userNationalID
                )
              }}
            </td>
            <td class="table-phone-number">
              {{
                isDataExist(
                  qrCodeSessionsData.cardData.userOwnerData.userPhoneWithCC
                )
              }}
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersPrivilegeData.usersPrivilegeQrCodeSessionsPurchases.add
                )
              "
            >
              <button
                v-b-modal.QrCodeSessionEnquirySellCard
                class="btn p-0"
                :title="$t('SellCards.sell')"
                @click="setQrCodeSessionData"
              >
                <img src="@/assets/images/sellCards.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkPrivilege, isDataExist } from "@/utils/functions";
import { BASE_URL } from "@/utils/constants";
import DefaultImgUser from "@/assets/images/user.svg";

export default {
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      baseUrl: BASE_URL,
      defaultImgUser: DefaultImgUser,
    };
  },
  props: ["qrCodeSessionsData"],
  methods: {
    setQrCodeSessionData() {
      this.$emit("setQrCodeSessionData", this.qrCodeSessionsData);
    },
    isDataExist,
    checkPrivilege,
  },
};
</script>
