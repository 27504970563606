import PaginationData from "@/models/general/PaginationData";
import QrCodeSessionsPurchase from "./QrCodeSessionsPurchase";
import QrCodeSessionsPurchasesFilter from "./QrCodeSessionsPurchasesFilter";

export default class QrCodeSessionsPurchases {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.qrCodeSessionsPurchasesData = [];
    this.qrCodeSessionsPurchase = new QrCodeSessionsPurchase();
    this.filterData = new QrCodeSessionsPurchasesFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.qrCodeSessionsPurchasesData = data.qrCodeSessionsPurchasesData;
  }
}
